<template>
  <div class="wrap">
    <img src="../static/img/404.png"
         alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100vh;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  img {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>